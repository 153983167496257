import { render, staticRenderFns } from "./galactiq-blogs.vue?vue&type=template&id=54b3860c&scoped=true"
var script = {}
import style0 from "./galactiq-blogs.vue?vue&type=style&index=0&id=54b3860c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54b3860c",
  null
  
)

export default component.exports